import {Component, OnInit } from '@angular/core';
import {GridOptions} from 'ag-grid-community';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'ngx-vendors',
  templateUrl: './vendors.component.html',
  styleUrls: ['./vendors.component.scss']
})
export class VendorsComponent implements OnInit  {
    private gridOptions: GridOptions;
    private gridApi;
    private gridColumnApi;
    rowData: any;
    columnDefs = [
      {headerName: 'Dispatch Date', field: 'dispatchDate', sortable: true, 
      filter: "agDateColumnFilter",
        filterParams: {
          comparator: function(filterLocalDateAtMidnight, cellValue) {
            var dateAsString = cellValue;
            if (dateAsString == null) return -1;
            var dateParts = dateAsString.split("/");
            var cellDate = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]));
            if (filterLocalDateAtMidnight.getTime() == cellDate.getTime()) {
              return 0;
            }
            if (cellDate < filterLocalDateAtMidnight) {
              return -1;
            }
            if (cellDate > filterLocalDateAtMidnight) {
              return 1;
            }
          },
          browserDatePicker: true
        },
      valueFormatter: this.dateFormatter},
      {headerName: 'Vendor Name', field: 'vendorName', sortable: true, filter: true},
      {headerName: 'WO #', field: 'workOrderNumber', sortable: true, filter: true},
      {headerName: 'Address', field: 'address', sortable: true, filter: true},
      {headerName: 'City', field: 'city', sortable: true, filter: true},
      {headerName: 'State', field: 'state', sortable: true, filter: true},
      {headerName: 'Zip', field: 'zip', sortable: true, filter: true},
      {headerName: 'Trades', field:'commaseperatedtrades', sortable: true, filter: true},
      {headerName: 'Status', field: 'status', sortable: true, filter: true},
    ];

    private REST_API_SERVER = 'http://api.stungie.com/api/report/GetVendorReport';
    constructor(private http: HttpClient) { }

    ngOnInit() {
     // this.rowData = this.http.get('https://api.myjson.com/bins/15psn9');
      this.rowData = this.sendGetRequest();
    }

    public sendGetRequest() {
      return this.http.get(this.REST_API_SERVER);
    }
    public dateFormatter(d){
      return d.value ? (new Date(d.value)).toLocaleDateString() : '';
    }
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      params.api.sizeColumnsToFit();
    }
    Export(): void {
      let params = {
        columnGroups: true,
        allColumns: true,
        fileName: 'export',
       // columnSeparator: document.querySelector('#columnSeparator').value
      };
     // console.log(params.columnSeparator);
      this.gridApi.exportDataAsCsv(params);
  }


}
