import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-reports',
  template: '<router-outlet></router-outlet>',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
