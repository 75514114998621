import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgGridModule } from 'ag-grid-angular';
import { ReportsComponent } from './reports.component';
import { VendorsComponent } from './vendors/vendors.component';
import { ReportsRoutingModule } from './reports-routing.module';
@NgModule({
  
  imports: [
    CommonModule,
    ReportsRoutingModule,
    AgGridModule.withComponents(
      []
    )
  ],
  declarations: [
    ReportsComponent,
    VendorsComponent
  ],
  
})
export class ReportsModule { }
