import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ReportsComponent } from './reports.component';
import { VendorsComponent } from './vendors/vendors.component';

const routes: Routes = [{
    path: '',
    component: ReportsComponent,
    children: [
      {
        path: 'vendors',
        component: VendorsComponent,
      },

    ],
  }];
  
  @NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
  })
  export class ReportsRoutingModule {
  }
  